import React, { Suspense, lazy } from "react";
import { Redirect, Switch, Route } from "react-router-dom";
import { LayoutSplashScreen, ContentRoute } from "../_metronic/layout";
// import { BuilderPage } from "./pages/BuilderPage";
// import { DashboardPage } from "./pages/DashboardPage";
import LocationsPage from "./modules/Data/subscriptions/locationsPage";
import UserSettings from "./modules/Data/subscriptions/userSettingsPage";
import LocationForecastPage from "./modules/Data/subscriptions/locationForecastPage";
import StationAvailableSensorsPage from "./modules/Data/subscriptions/stationAvailableSensorsPage";
import VirtualStationAvailableSensorsPage from "./modules/Data/subscriptions/virtualStationAvailableSensorsListPage";
import StationCultivationDiseasesPage from "./modules/Data/subscriptions/stationCultivationDiseasesPage";
import StationDiseasesCultivationsPage from "./modules/Data/subscriptions/stationDiseasesCultivationsPage";
import StationInsectOccurrencesCultivationsPage from "./modules/Data/subscriptions/stationInsectOccurrencesCultivationsPage";
import LocationCultivationInsectOccurrencesPage from "./modules/Data/subscriptions/locationCultivationInsectOccurrencesPage";
import StationInsectModelsCultivationsPage from "./modules/Data/subscriptions/stationInsectModelsCultivationsPage";
import LocationCultivationInsectModelsPage from "./modules/Data/subscriptions/locationCultivationInsectModelsPage";
import BuyAccessPage from "./modules/Data/subscriptions/buyAccessPage";
import CalculatorFertilizer from "./modules/Data/subscriptions/calculatorFertilizer";
import CalculatorEmission from "./modules/Data/subscriptions/calculatorEmission";
import UserSubscriptions from "./modules/Data/subscriptions/userSubscriptions";
import VirtualLocationsPage from "./modules/Data/subscriptions/virtualLocationsPage";
import VirtualStationForecastPage from "./modules/Data/subscriptions/virtualStationForecastPage";
import AgentAffiliatePage from "./modules/Data/subscriptions/agentAffiliatePage";
import AgentPanelPage from "./modules/Data/subscriptions/agentPanelPage";
import UserSubscriptionsOrders from "./modules/Data/subscriptions/userSubscriptionsOrders";
import AgentNewClientPage from "./modules/Data/subscriptions/agentNewClientPage";
import AgentPanelPageSubscriptions from "./modules/Data/subscriptions/agentPanelPageSubscriptions";
import AgentPanelPageSubscriptionsNew from "./modules/Data/subscriptions/agentPanelPageSubscriptionsNew";
import AgentMinTempPage from "./modules/Data/subscriptions/agentMinTemp";

const DataRoutes = lazy(() =>
  import("./modules/Data/DataRoutes")
);
const UserRoutes = lazy(() =>
  import("./modules/UserProfile/UserProfilePage")
);

export default function BasePage() {

  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <Switch>
        {
          <Redirect exact from="/" to="/locations" />
        }
        <ContentRoute exact={true} from="/user-profile" component={UserSettings} />
        <ContentRoute exact={true} from="/locations" component={LocationsPage} />
        <ContentRoute exact={true} from="/locations/:stationId/forecast" component={LocationForecastPage} />
        <ContentRoute exact={true} from="/locations/virtual/:virtualStationId/sensors" component={VirtualStationAvailableSensorsPage} />
        <ContentRoute exact={true} from="/virtual" component={VirtualLocationsPage} />
        <ContentRoute exact={true} from="/virtual/:virtualStationId/sensors" component={VirtualStationAvailableSensorsPage} />
        <ContentRoute exact={true} from="/virtual/:virtualStationId/forecast" component={VirtualStationForecastPage} />
        <ContentRoute exact={true} from="/locations/:stationId/sensors" component={StationAvailableSensorsPage} />
        <ContentRoute exact={true} from="/locations/:stationId/diseases/cultivation/:cultivationId" component={StationCultivationDiseasesPage} />
        <ContentRoute exact={true} from="/locations/:stationId/diseases/cultivations" component={StationDiseasesCultivationsPage} />
        <ContentRoute exact={true} from="/locations/chart/:stationId/sensor/:sensorId" component={StationAvailableSensorsPage} />
        <ContentRoute exact={true} from="/locations/:stationId/insectOccurrences/cultivations" component={StationInsectOccurrencesCultivationsPage} />
        <ContentRoute exact={true} from="/locations/:stationId/insectModels/cultivations" component={StationInsectModelsCultivationsPage} />
        <ContentRoute exact={true} from="/locations/:stationId/insectOccurrences/cultivation/:cultivationId" component={LocationCultivationInsectOccurrencesPage} />
        <ContentRoute exact={true} from="/locations/:stationId/insectModels/cultivation/:cultivationId" component={LocationCultivationInsectModelsPage} />
        <ContentRoute exact={true} from="/buy" component={BuyAccessPage} />
        <ContentRoute exact={true} from="/calculator/fertilization" component={CalculatorFertilizer} />
        <ContentRoute exact={true} from="/calculator/emission" component={CalculatorEmission} />
        <ContentRoute exact={true} from="/subscriptions" component={UserSubscriptions} />
        <ContentRoute exact={true} from="/subscriptions/orders" component={UserSubscriptionsOrders} />
        <ContentRoute exact={true} from="/affiliatePanel" component={AgentAffiliatePage} />
        <ContentRoute exact={true} from="/agentPanel/minTemp" component={AgentMinTempPage} />
        <ContentRoute exact={true} from="/agentPanel/newClient" component={AgentNewClientPage} />
        <ContentRoute exact={true} from="/agentPanel/subscriptions/:userId/new" component={AgentPanelPageSubscriptionsNew} />
        <ContentRoute exact={true} from="/agentPanel/subscriptions/:userId" component={AgentPanelPageSubscriptions} />
        <ContentRoute exact={true} from="/agentPanel" component={AgentPanelPage} />

        {/* <ContentRoute path="/user-profile" component={UserRoutes} /> */}
        {/* <ContentRoute path="/data" component={DataRoutes} /> */}
        <Redirect to="/" />
      </Switch>
    </Suspense>
  );
}
