/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid,jsx-a11y/role-supports-aria-props */
import { Checkbox } from '@material-ui/core';
import LocationOn from '@material-ui/icons/LocationOn';
import axios from 'axios';
import GoogleMapReact from 'google-map-react';
import React, { useEffect, useRef, useState } from "react";
import { Button } from "react-bootstrap";
import ReCAPTCHA from "react-google-recaptcha";
import { useIntl } from "react-intl";
import { useDispatch } from "react-redux";
import { useLang } from "../../../_metronic/i18n/Metronici18n";
import { useSubheader } from "../../../_metronic/layout";
import googleMap from "../Shared/PublicKeys/googleMapKey";
import reCaptchaKey from "../Shared/PublicKeys/reCaptcha";
import * as Yup from 'yup';
import { toast } from 'react-toastify';

var marker;
var lines = [];

export default function SignUpMapPage({
  history,
  companyName
}) {
  const subheader = useSubheader();
  const dispatch = useDispatch();
  const langName = useLang();
  const intl = useIntl();
  const { messages: msg } = intl;
  const recaptcha = useRef();
  const [map, setMap] = useState(null);
  const [maps, setMaps] = useState(null);
  const [stations, setStations] = useState(null);
  const [cultivations, setCultivations] = useState(null);
  const [cultivation, setCultivation] = useState(null);
  const [cultivation2, setCultivation2] = useState(null);
  const [cultivation3, setCultivation3] = useState(null);
  const [cultivation4, setCultivation4] = useState(null);
  const [cultivation5, setCultivation5] = useState(null);
  const [selectedStation, setSelectedStation] = useState(null);
  const [postCode, setPostCode] = useState('');
  const [postCodeCoords, setPostcodeCoords] = useState({lat: null, lng: null});
  const [nearestDistance, setNearestDistance] = useState('');
  const [nearestDistanceDescription, setNearestDistanceDescription] = useState('');
  const [nearestDistanceColor, setNearestDistanceColor] = useState('transparent');
  const [userMobile, setUserMobile] = useState('');

  const [emailErrorMsg, setEmailErrorMsg] = useState('');
  const [passwordErrorMsg, setPasswordErrorMsg] = useState('');
  const [pzuErrorMsg, setPzuErrorMsg] = useState('');
  const [mobileErrorMsg, setMobileErrorMsg] = useState('');
  const [postCodeOrLocErrorMsg, setPostCodeOrLocErrorMsg] = useState('');

  const [isPasswordVisible, setIsPasswordVisible] = useState(false);

  const formRef = useRef();
  const mapRef = useRef();
  const cultivationsRef = useRef();
  const postCodeRef = useRef();

  function createEmailValidation() {
    return Yup.string()
      .required("To pole jest wymagane")
      .max(120, "Max. 120 znaków)")
      .email("Nieprawidłowy adres email");
  }
  function createPasswordValidation(invalidPasswordMsg) {
    return Yup.string()
      .required("To pole jest wymagane")
      .min(8, "Zbyt krótkie hasło (min. 8 znaków)")
      .max(100, "Zbyt długie hasło (max. 100 znaków)")
      .matches(/[A-Z]/, "Minimum 1 wielkia litera")
      .matches(/[a-z]/, "Minimum 1 mała litera")
      .matches(/[0-9]/, "Musi zawierać min. 1 cyfrę")
      .test('no-whitespace', "Nie może zawierać spacji ani odstępów", value => !/\s/.test(value))
  }
  function createCustomCodeValidation() {
    return Yup.string()
      .required("To pole jest wymagane")
      .matches(/^.{7}$/, "Wprowadź 7 znaków")
      .matches(/^[A-Za-z]{3}[0-9]{4}$/, "Nieprawidłowy kod");
  }
  function createPhoneNumberValidation() {
    return Yup.string()
      .required("To pole jest wymagane")
      .matches(/^\d{9}$/, "Podaj 9 cyfr");
  }
  function createPostalCodeOrCityValidation() {
    return Yup.string()
      .required("To pole jest wymagane")
      .test('is-postal-or-city', "Proszę wprowadzić właściwą wartość", value => {
        const postalCodeRegex = /^\d{2}-\d{3}$/;
        const cityRegex = /^[A-Za-zżźćńółęąśŻŹĆĄŚĘŁÓŃ ]{2,}$/;
        return postalCodeRegex.test(value) || cityRegex.test(value);
      });
  }
  const validationSchema = Yup.object().shape({
    email: createEmailValidation(),
    password: createPasswordValidation(),
    pzu: createCustomCodeValidation(),
    mobile: createPhoneNumberValidation(),
    postCode: createPostalCodeOrCityValidation(),
  });
  const updateErrorHook = (fieldName, fieldValue, errorMessageHook) => {
    validationSchema
        .validateAt(fieldName, { [fieldName]: fieldValue })
        .then(() => errorMessageHook(""))
        .catch((error) => errorMessageHook(error.message));
  };

  const handleApiLoaded = async (mapEntity, mapsEntity) => {
    setMap(mapEntity);
    setMaps(mapsEntity);
  };

  useEffect(() => {
    const instance = axios.create({
      baseURL: 'https://api.admin.farmsmart.pl/getStationAvailableForUser'
    });

    instance('').then(res => {
      const {data} = res;
      setStations(data);
    });

    const instance2 = axios.create({
      baseURL: 'https://api.admin.farmsmart.pl/getCultivationsAvailableForUser'
    });

    instance2('').then(res => {
      const {data} = res;

      setCultivations(data.sort(compare));
    });
  }, []);

  const isMobile = window.matchMedia("(max-width: 1000px)").matches;
  const [mapSettings, setMapSettings] = useState({
    center: {
      lat: 52.133231,
      lng: 19.596804
    },
    zoom: isMobile ? 5 : 6,
  });

  function compare( a, b ) {
    if ( a.names[0] < b.names[0] ){
      return -1;
    }
    if ( a.names[0] > b.names[0] ){
      return 1;
    }
    return 0;
  }

  const [userEmail, setUserEmail] = useState('');
  const [userPassword, setUserPassword] = useState('');
  const [termsAccept, setTermsAccept] = useState(false);
  const [logoImage, setLogoImage] = useState('');
  const [token, setToken] = useState('');
  const [code, setCode] = useState('');
  const [maxPostcodeOrCityLength, setMaxPostcodeOrCityLength] = useState(40);
  const [markerPos, setMarkerPos] = useState({lat: null, lng: null});

  useEffect(()=>{
    if (!isNaN(postCode.charAt(0)) && !isNaN(postCode.charAt(1)) ) {
      setMaxPostcodeOrCityLength(6);
      if (postCode.length === 2) {
        setPostCode(`${postCode}-`);
      }
    }
    else {
      setMaxPostcodeOrCityLength(40);
    }
  }, [postCode]);

  const getPostcodeCoords = async (locationString) => {
    try {
      axios.post(`/getLocationCoords`,
        { locationString 
      }).then((res) => {
        // const {lat, lng} = res.data.results[0].geometry.location;
        const {lat, lng} = res.data;
        setPostcodeCoords({lat, lng});
        map.setCenter({lat, lng});

        if (postCode == '')
        map.setZoom(7);
        resetLines();
        putMarker(lat, lng);
        
      });
    } catch (e) {
    }
  }

  const calculateDistance = (lat1, lon1, lat2, lon2) => {    
    if ((lat1 == lat2) && (lon1 == lon2)) {
      return 0;
    }
    else {
      var radlat1 = Math.PI * lat1/180;
      var radlat2 = Math.PI * lat2/180;
      var theta = lon1-lon2;
      var radtheta = Math.PI * theta/180;
      var dist = Math.sin(radlat1) * Math.sin(radlat2) + Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
      if (dist > 1) {
        dist = 1;
      }
      dist = Math.acos(dist);
      dist = dist * 180/Math.PI;
      dist = dist * 60 * 1.1515;
      dist = dist * 1.609344;
      return dist;
    }
    }
  
  const drawLines = (lat, lng, selectedStation = null) => {
    var dist = null;
    var stationId = null;
    stations.map((location)=> {
      const distance = calculateDistance(location.latitude, location.longitude, lat, lng);
      if (dist === null || distance < dist) {
        dist = distance;
        stationId = location.id;
      }
    });
    if (selectedStation != null) stationId = selectedStation;

    stations.map((location)=> {
      const distance = calculateDistance(location.latitude, location.longitude, lat, lng);
      if (distance <= 20 || location.id == stationId)
      {
        var newline = new maps.Polyline({
          path: [
              new maps.LatLng(lat, lng), 
              new maps.LatLng(location.latitude, location.longitude)
          ],
          strokeColor: stationId == location.id?"#FF0000":"#aaa",
          strokeOpacity: 1.0,
          strokeWeight: 2,
          map: map
        });
        lines.push(newline);
      }   
    });
  }
  const markNearestStation = (lat, lng) => {
    var calculatedDistance = null;
    var nearestStation = null;
    stations.map((location)=> {

      const distance = calculateDistance(location.latitude, location.longitude, lat, lng);
     
      if (distance < calculatedDistance || calculatedDistance === null) {
        calculatedDistance = distance;
        nearestStation = location;
      }
    })
    setSelectedStation(nearestStation);
    setNearestDistance(calculatedDistance);
    showStationDescription(calculatedDistance);
  }

  const showStationDescription = (calculatedDistance) => {
    var description = `Odległość od zaznaczonej stacji ${Math.round(calculatedDistance, 1)} km - `;
    var distanceDescription = 'wysoka dokładność.';
    setNearestDistanceColor('#019640');
    if (calculatedDistance > 10) {
      distanceDescription = 'średnia dokładność.';
      setNearestDistanceColor('#93c21c')
    }
    if (calculatedDistance > 20) {
      distanceDescription = 'niska dokładność.';
      setNearestDistanceColor('lightcoral');
    }
    setNearestDistanceDescription(`${description}${distanceDescription}`)
  }

  const resetLines = _ => {
    if (lines.length > 0) {
      for (let loop=0; loop<lines.length; loop++) 
      {                           
        lines[loop].setMap(null);
      }
      lines = [];
    }
  }

  const putMarker = (lat, lng) => {
    if (marker!=null)
        marker.setMap(null);
      marker = new maps.Marker({
        position: {lat, lng},
        map,
        draggable:true,
        labelInBackground: false,
        labelAnchor: new maps.Point(0, 40),
        animation: maps.Animation.DROP,
        label: {
          text: `${msg.DRAG_TO_SELECT_LOCATION}`,
          color: 'black',
          fontSize: '16px',      
          className: 'marker-position',
        },
      });
      setTimeout(() => {
        drawLines(lat, lng);
        markNearestStation(lat, lng);
      }, 1000);
      marker.addListener('drag', e => {
        let {lat, lng} = marker.getPosition().toJSON();
        setPostcodeCoords({lat, lng});
        resetLines();
        drawLines(lat, lng);
      });

      marker.addListener('dragend', e => {
        let {lat, lng} = marker.getPosition().toJSON();
        if (map.getZoom() < 10)
        map.setZoom(10);
        map.setCenter({lat, lng});
        setMarkerPos({lat, lng});
        markNearestStation(lat, lng);
      });
}

  useEffect(() => {
    document.title = "Tworzenie nowego konta";

    switch(companyName) {
      case 'Plantpress': setLogoImage('plantpress'); break;
      case 'PZU': setLogoImage('PZU'); break;
      case 'PodOslonami': setLogoImage('podoslonami'); break;
    }
  }, []);

  const postData = async (token) => {
    try {
      
      await axios.post('createAccountTemporaryForPZU', {userEmail, userPassword,  companyName, token, selectedStation, cultivation, userMobile, cultivation2, cultivation3, cultivation4, cultivation5}).then(function(response) {

        if (response.data?.err) {

          let decoded = JSON.parse(response.data.err);

          switch(decoded.message) {
            case 'Another object with the same value for property userPrincipalName already exists.': toast.error("Użytkownik z podanym adresem email już istnieje."); break;
            case 'Another object with the same value for property proxyAddresses already exists.': toast.error("Proszę podać inny adres email."); break;
            default: toast.error(decoded.message); break;
          }        
          recaptcha.current.reset();
        } else {
          toast.success('Konto zostało utworzone.');
          setTimeout(() => {
            window.location.href = '/';
          }, 3000);
        }
      });
    } catch(e) {
      console.log(e);
    }
  }

  const verify = async () => {
    updateErrorHook('email', userEmail, setEmailErrorMsg);
    updateErrorHook('password', userPassword, setPasswordErrorMsg);
    updateErrorHook('pzu', code, setPzuErrorMsg);
    updateErrorHook('mobile', userMobile, setMobileErrorMsg);
    updateErrorHook('postCode', postCode, setPostCodeOrLocErrorMsg);

    const cultivationsFilledValues = [cultivation, cultivation2, cultivation3, cultivation4, cultivation5]
    .filter(c => {if (c !== null && c !== 'Wybierz uprawę z listy') {
        return c;
    }});
  
    const captchaValue = recaptcha.current.getValue();
    if (emailErrorMsg || passwordErrorMsg || pzuErrorMsg || mobileErrorMsg || !userEmail || !userPassword || !code || !userMobile) {
      toast.error("Proszę poprawnie wypełnić formularz");
      formRef.current?.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
    else if (postCodeOrLocErrorMsg || !postCode) {
      toast.error("Proszę wpisać kod pocztowy lub miejscowość.");
      postCodeRef.current?.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
    else if (selectedStation == null) {
      toast.error("Proszę wybrać stację \n(kliknij w punkt na mapie).");
      mapRef.current?.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
    else if (cultivationsFilledValues < 1) {
      toast.error("Proszę wybrać przynajmniej jedną uprawę.");
      cultivationsRef.current?.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
    else if (new Set(cultivationsFilledValues).size !== cultivationsFilledValues.length) {
      toast.error("Wybrane uprawy powtarzają się.");
      cultivationsRef.current?.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
    else if (!captchaValue) {
      toast.error("Proszę zaznaczyć pole \"Nie jestem robotem\".");
      setToken(token);
    }
    else if (!termsAccept) toast.error("Proszę zaakceptować regulamin.");
    else {
      await postData(captchaValue);
    }
  }

  let Marker = ({location, markerColor, markerSize, entity}) => {
    let {name, latitude, longitude} = location;
    return (
      <div lat={latitude} lng={longitude}>
        <LocationOn 
          onClick={()=>clickMarker(entity)} 
          style={{
            color: markerColor,
            fontSize: `${markerSize}px`,
            marginTop: '-38px',
            marginLeft: '-16px'
          }} 
        />
        <h4>{name}</h4>
      </div>
    )
  }
  
  const clickMarker = (location) => {
    setSelectedStation(location);
    if (postCodeCoords.lat != null)
    {
      var tempDist = calculateDistance(location.latitude, location.longitude, postCodeCoords.lat, postCodeCoords.lng);
      showStationDescription(tempDist);
      resetLines();
      drawLines(postCodeCoords.lat, postCodeCoords.lng, location.id);
    }
  }
  const handleToggle = () => setIsPasswordVisible(!isPasswordVisible);

  // the primitive solution for styling below was caused by the nature of current PZU cooperation process (enforced new style that doesnt match the rest of the app, multiple temporary solutions)
  
  // needs to be outside cause of font import
  const mainStyle = {
    fontFamily: 'Inter, sans-serif',
    '@import': 'url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900;&display=swap")',
    width: "100%",
    backgroundColor: "white",
    color: "black",
  };
  const labelStyle = {
    display: 'block', 
    marginLeft: "8px", 
    marginBottom: '2px', 
    fontSize: "12px", 
    fontWeight: "bold", 
  }
  const inputFieldStyle = {
    fontSize: "15px",
    padding: "0px 14px",
    margin: "2px 0px",
    width: '100%',
    borderColor: '#459BD9',
    borderRadius: "8px",
    height: "2.9em",
  }
  const inputFieldStyleTemp = {
    fontSize: "15px",
    padding: "0px 14px",
    margin: "2px 0px",
    width: '100%',
    borderColor: '#459BD9',
    borderRadius: "8px",
    height: "2.9em",
    flex: 1,
  }
  const underlinedTitleStyle = {
    margin: "40px 0 15px 0", 
    fontSize: "2.3rem", 
    fontWeight: "bold", 
    color: "#0C344D", 
    lineHeight: "2.7rem",
  }
  const ErrorMsgStyle = {
    color: 'red', 
    position: 'relative', 
    top: '1px', 
    left: '8px', 
    marginBottom: '5px',
  }
  const mainVidHeight = () => {
    const isMobile = window.matchMedia("(max-width: 1000px)").matches;
    return {
      height: isMobile ? "200px" : "400px",
    }
  }
  const mapStyle = () => {
    const isMobile = window.matchMedia("(max-width: 1000px)").matches;
    return {
      height: isMobile ? "220px" : "450px",
      border: "1px #459BD9 solid", 
      overflow: "hidden"
    }
  }

  const StepComponent = ({step, text}) => {
    // extended path, cause for now we must avoid installing additional packages
    const paths = {
      1: "M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0M9.283 4.002H7.971L6.072 5.385v1.271l1.834-1.318h.065V12h1.312z",
      2: "M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0M6.646 6.24c0-.691.493-1.306 1.336-1.306.756 0 1.313.492 1.313 1.236 0 .697-.469 1.23-.902 1.705l-2.971 3.293V12h5.344v-1.107H7.268v-.077l1.974-2.22.096-.107c.688-.763 1.287-1.428 1.287-2.43 0-1.266-1.031-2.215-2.613-2.215-1.758 0-2.637 1.19-2.637 2.402v.065h1.271v-.07Z",
      3: "M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0m-8.082.414c.92 0 1.535.54 1.541 1.318.012.791-.615 1.36-1.588 1.354-.861-.006-1.482-.469-1.54-1.066H5.104c.047 1.177 1.05 2.144 2.754 2.144 1.653 0 2.954-.937 2.93-2.396-.023-1.278-1.031-1.846-1.734-1.916v-.07c.597-.1 1.505-.739 1.482-1.876-.03-1.177-1.043-2.074-2.637-2.062-1.675.006-2.59.984-2.625 2.12h1.248c.036-.556.557-1.054 1.348-1.054.785 0 1.348.486 1.348 1.195.006.715-.563 1.237-1.342 1.237h-.838v1.072h.879Z",
      4: "M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0M7.519 5.057c-.886 1.418-1.772 2.838-2.542 4.265v1.12H8.85V12h1.26v-1.559h1.007V9.334H10.11V4.002H8.176zM6.225 9.281v.053H8.85V5.063h-.065c-.867 1.33-1.787 2.806-2.56 4.218",
      5: "M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0m-8.006 4.158c1.74 0 2.924-1.119 2.924-2.806 0-1.641-1.178-2.584-2.56-2.584-.897 0-1.442.421-1.612.68h-.064l.193-2.344h3.621V4.002H5.791L5.445 8.63h1.149c.193-.358.668-.809 1.435-.809.85 0 1.582.604 1.582 1.57 0 1.085-.779 1.682-1.57 1.682-.697 0-1.389-.31-1.53-1.031H5.276c.065 1.213 1.149 2.115 2.72 2.115Z",
    };
    const className = `bi bi-${step}-circle-fill`;
    return (
      <>
        <div className="mt-11" style={{fontSize: "1.6rem", lineHeight: "2.5rem", color: "#459BD9"}}>
          <svg xmlns="http://www.w3.org/2000/svg" style={{position: "relative", top: "-0.2rem"}} width="3.2rem" height="3.2rem" fill="currentColor" viewBox="-1 -1 18 18" class={className}>
            <path d={paths[step]} stroke="white" strokeWidth="0.1" />
          </svg>
          <span className='ml-2'><b>Krok</b></span>
          &nbsp;-&nbsp;{text}
        </div>
        <br/>
      </>
    )
  }

  const VideoContainer = ({description, link}) => {
    return (
      <div className='col-12 col-md-6 p-0 mr-5'>
        <h6><b>{description}</b></h6>
        <iframe height="200px" src={link} className='rounded w-100'
        ></iframe>
      </div>
    )
  }

  const PickCultivation = ({ cultValue, cultToSet }) => {
    return (
      <div className='flex mb-3'>
        <select
          className="form-control"
          style={inputFieldStyle}
          value={cultValue}
          onChange={e => cultToSet(e.target.value)}
        >
          <option value={null}>Wybierz uprawę z listy</option>
          {cultivations && cultivations.map(cult => (
            <option value={cult.id} key={cult.id}>{cult.names[0]}</option>
          ))}
        </select>
        <div className='text-danger mt-1'></div>
      </div>
    );
  };

  return (
    <div className="text" style={mainStyle}>
      <div className="rounded" style={{maxWidth: '960px', width: '100%', minWidth: "30px", margin: '20px auto', backgroundColor: '#EEF0F8'}}>

        <div className="text col-9 col-md-7 p-0">
          <img src="/media/logos/farm-smart-logo.png" className='w-50 my-8' style={{marginLeft: '8%'}} />
        </div>
        <div className="rounded py-12 px-6 px-md-14" style={{width: '90%', margin: '0px auto', backgroundColor: 'white'}}>
          <div className='mb-3 display-4'><b>Dzień dobry!</b></div>
          <span className='h4'><b>Jesteś w formularzu rejestracyjnym systemu Farm Smart. To oferta specjalna we&nbsp;współpracy z PZU.</b></span>
          <div className="mt-7">
            <iframe className='rounded w-100' style={mainVidHeight()} src="https://www.youtube.com/embed/-Wx9Ao0AErU"></iframe>
          </div>
        </div>

        <div className="rounded px-6 px-md-14" style={{width: '90%', margin: '20px auto'}}>
          <div className='mt-10'>
            <h3>Uzupełnij poniższy formularz w celu otrzymania dostępu do:</h3>
            <ul className='h4'>
              <li>prognozy pogody z informacją o optymalnym terminie oprysku,</li>
              <li>danych pogodowych z najbliższej stacji pogodowej,</li>
              <li>kalkulatora bilansu CO2 w uprawie,</li>
              <li>managera pola.</li>
            </ul>
          </div>

          <StepComponent step={1} text="tutaj wpisz swój e-mail, hasło, numer telefonu i&nbsp;podaj kod z PZU" />
          <div ref={formRef} className='mb-4 col-12 d-block d-md-flex p-0'>
            <div className="col-12 col-md-6 p-0 pr-0 pr-md-2">
                <label style={labelStyle}>ADRES E-MAIL</label>
                <div className='d-flex'>
                  <input type="email" 
                    className="form-control" 
                    placeholder={`Wpisz swój adres e-mail`} 
                    style={inputFieldStyle} 
                    value={userEmail} 
                    onChange={e=>setUserEmail(e.target.value)}
                    onBlur={e => {updateErrorHook('email', e.target.value, setEmailErrorMsg)}}
                  />
                </div>
                <div style={ErrorMsgStyle}>{emailErrorMsg}</div>
            </div>
            <div className="col-12 col-md-6 p-0">
              <label style={labelStyle}>HASŁO</label>
              <div className='d-flex position-relative align-items-center'>
                <input 
                  type = {isPasswordVisible ? 'text' : 'password'}
                  className="form-control" 
                  placeholder={`Ustaw swoje hasło`} 
                  style={inputFieldStyleTemp}  
                  value={userPassword} 
                  onChange={e=>setUserPassword(e.target.value)}
                  onBlur={e => {updateErrorHook('password', e.target.value, setPasswordErrorMsg)}}
                />
                <i className="material-icons" onClick={handleToggle} title="Ukryj hasło" style={{display: isPasswordVisible ? 'block' : 'none', position: "absolute", right: "15px", color: '#DDD', fontSize: "25px", backgroundColor: "white", cursor: "pointer"}}>
                  visibility_off
                </i>
                <i className="material-icons" onClick={handleToggle} title="Pokaż hasło" style={{display: isPasswordVisible ? 'none' : 'block', position: "absolute", right: "15px", color: '#DDD', fontSize: "25px", backgroundColor: "white", cursor: "pointer"}}>
                  visibility
                </i>
              </div>
              <div style={ErrorMsgStyle}>{passwordErrorMsg}</div>
            </div>
          </div>
          <div className='mb-4 col-12 d-block d-md-flex p-0'>
            <div className="col-12 col-md-6 p-0 pr-0 pr-md-2">
              <label style={labelStyle}>KOD PZU</label>
              <input type="text" 
                className="form-control" 
                placeholder={`Wpisz kod w formacie PZUXXXX`} 
                style={inputFieldStyle} 
                value={code} 
                onChange={e=>setCode(e.target.value)}
                onBlur={e => {updateErrorHook('pzu', e.target.value, setPzuErrorMsg)}}
              />
              <span className="p-2">XXXX to 4 ostatnie cyfry numeru polisy</span>
              <div style={ErrorMsgStyle}>{pzuErrorMsg}</div>
            </div>
            <div className="col-12 col-md-6 p-0 pt-3 pt-md-0">
              <label style={labelStyle}>NUMER TELEFONU KOMÓRKOWEGO</label>
              <input type="number" 
                className="form-control" 
                placeholder={`Wpisz 9 cyfrowy numer telefonu`} 
                style={inputFieldStyle} 
                value={userMobile} 
                onChange={e=>setUserMobile(e.target.value)}
                onBlur={e => {updateErrorHook('mobile', e.target.value, setMobileErrorMsg)}}
              />
              <div style={ErrorMsgStyle}>{mobileErrorMsg}</div>
            </div>
          </div>
          <StepComponent step={2} text="tutaj wpisz swój kod pocztowy (lub miejscowość) i wybierz najbliższą stację meteorologiczną" />
          <div className='mb-4 col-md-12 d-block d-md-flex p-0'>
            <div ref={postCodeRef} className="col-12 col-md-6 p-0 pr-0 pr-md-2">
              <label style={labelStyle}>KOD POCZTOWY LUB MIEJSCOWOŚĆ</label>
              <input type="text" 
                className="form-control" 
                placeholder={"Wpisz kod pocztowy lub miejscowość"} 
                style={inputFieldStyle} 
                value={postCode} 
                maxLength={maxPostcodeOrCityLength}
                onChange={e=>{if (e.nativeEvent.data == '-') return false; if (e.nativeEvent.inputType === 'deleteContentBackward') setPostCode(''); else setPostCode(e.target.value)}}
                onKeyDown={e => {if (e.nativeEvent.key == 'Enter') {getPostcodeCoords(postCode); if (postCode!='') map.setZoom(10)}}}
                onBlur={e => {updateErrorHook('postCode', e.target.value, setPostCodeOrLocErrorMsg)}}
              />
              <div style={ErrorMsgStyle}>{postCodeOrLocErrorMsg}</div>
            </div>
            <div className="col-12 col-md-6 p-0 pr-0 pr-md-2">
              <label className='d-none d-md-block' style={labelStyle}>&nbsp;</label>
              <Button 
                className="w-100 rounded" style={{fontSize: "15px", height: "2.9em", padding: "0px 14px"}} 
                onClick={()=>{getPostcodeCoords(postCode); if (postCode!='') map.setZoom(10)}} 
                >
                {msg.SHOW_NEAREST_LOCATIONS}
              </Button>
            </div>
          </div>
          
          <div ref={mapRef} className="rounded" style={mapStyle()}>
            {postCodeCoords.lat !=null && <div style={{width:'100%', height: 34, backgroundColor: nearestDistanceColor, padding:5, textAlign: 'center', color: 'white', fontSize: 16}}>{nearestDistanceDescription}</div>}
            <GoogleMapReact
              bootstrapURLKeys={{key: googleMap.publicKey}}
              defaultCenter={mapSettings.center}
              defaultZoom={mapSettings.zoom}
              yesIWantToUseGoogleMapApiInternals
              onGoogleApiLoaded={({ map, maps }) => handleApiLoaded(map, maps)}
              options={{'dataMode': 'regions', 'region': 'PL'}}
            >
              {stations && stations.map((station, key) => {
                return <Marker key={key} id={station.id} lat={station.latitude} lng={station.longitude} location={station.name} markerColor={station.id!==selectedStation?.id?'green':'darkblue'} markerSize={28} entity={station} />;
              })}
            </GoogleMapReact>
          </div>
          <StepComponent step={3} text="Wybierz do pięciu upraw, a otrzymasz bezpłatnie dostęp do monitoringu infekcji chorobowych na 14 dni" />
          <div ref={cultivationsRef}>
            <label style={labelStyle}>WYBIERZ DO 5 UPRAW</label>
            <PickCultivation cultValue={cultivation} cultToSet={setCultivation}/>
            <PickCultivation cultValue={cultivation2} cultToSet={setCultivation2}/>
            <PickCultivation cultValue={cultivation3} cultToSet={setCultivation3}/>
            <PickCultivation cultValue={cultivation4} cultToSet={setCultivation4}/>
            <PickCultivation cultValue={cultivation5} cultToSet={setCultivation5}/>
          </div>
          <StepComponent step={4} text="zaznacz, że nie jesteś robotem i zaakceptuj regulamin" />
          <div className="d-md-flex d-block align-items-center">
            <div className='position-relative mr-3'>
              <ReCAPTCHA ref={recaptcha} sitekey={reCaptchaKey.publicKey} />
            </div>
            <div className="rounded h-100 mr-3 mt-2 mt-md-0" style={{backgroundColor: "#f9f9f9", padding:"18px 20px", border:"1px solid #d3d3d3"}}>
              <Checkbox
                checked={termsAccept}
                onChange={e=>setTermsAccept(e.target.checked)}
                value="termsAccept"
                style={{transform: 'scale(1.7)', marginTop: "-2.5px", color: "#BBB"}}
              /> Akceptuję <a href="https://farmsmart.pl/regulamin-portalu/" target="_blank"><u>regulamin</u></a>
            </div>
          </div>
          <StepComponent step={5} text="kliknij w przycisk poniżej i GOTOWE!" />
          <Button style={{height: "64px", fontSize: "1.25rem"}} className="btn w-100" onClick={_=>{verify()}}>
            <b>REJESTRUJĘ BEZPŁATNE KONTO FARM SMART</b>
          </Button>

          <div className='d-flex flex-column justify-content-center rounded p-6 p-md-10 pt-8 pt-md-10' style={{height: "116px", margin: "60px 0px", backgroundColor: "#F5E400"}}>
            <h2><b>Masz pytania?</b></h2>
            <h4>Zadzwoń lub napisz do naszego eksperta.<br/>Arkadiusz PLISZKA / <b>728 847 285</b> / arkadiusz.pliszka@agrosmartlab.com</h4>
          </div>
          <div style={underlinedTitleStyle}>
            Zobacz prezentację systemu Farm Smart
            <hr className='mt-3' style={{backgroundColor: "#459BD9"}}></hr>
          </div>
          <div className='col-12 col-md-12 d-block d-md-flex p-0'>
            <VideoContainer description="Prezentacja systemu Farm Smart" link="https://www.youtube.com/embed/q-QbGVXv-8s" />
          </div>
          <div style={underlinedTitleStyle}>
            Zobacz na poniższych filmach co rolnicy mówią o Farm Smart
            <hr className='mt-3' style={{backgroundColor: "#459BD9"}}></hr>
          </div>
          <div className='col-12 col-md-12 d-block d-md-flex p-0'>
            <VideoContainer description="Producent warzyw" link="https://www.youtube.com/embed/CO81ieWGPhs" />
            <VideoContainer description="Producent owoców" link="https://www.youtube.com/embed/4uAP0cTblKc"/>
          </div>
          <br/><br/>
        </div>
      </div>
    </div>
  );
}
