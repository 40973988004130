import React, { useEffect, useState, useRef } from "react";
import { useDispatch, shallowEqual, useSelector } from "react-redux";
import * as afActions from "./affiliateActions";
import { sortCaret } from "../../../../_metronic/_helpers";
import BootstrapTable from 'react-bootstrap-table-next';
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../../_metronic/_partials/controls";
import { useIntl } from "react-intl";
import { registerLocale } from "react-datepicker";
import { Button } from "react-bootstrap";

export default function AgentPanelPage({
  history
}) {

  const { user } = useSelector((state) => state.auth);

  registerLocale('currentDatePickerLang', {
    localize: {
      month: n => msg.MONTHS[n],
      day: n => msg.DAYS[n]
    },
    options: {
      weekStartsOn: 1
      /* Monday */
      ,
      firstWeekContainsDate: 2
    },
    formatLong: {}
  });

  const dispatch = useDispatch();
  const intl = useIntl();
  const { messages: msg } = intl;
  const [title, setTitle] = useState(msg["AGENT_ACCOUNT"]);
  const year =  new Date().getFullYear();
  const month = `${new Date().getMonth()<10?'0':''}${new Date().getMonth()+1}`;
  const day =  `${new Date().getDate()<10?'0':''}${new Date().getDate()}`;
  
  const { companyUsers } = useSelector(
    (state) => ({
      companyUsers: state.affiliate.companyUsers,
      error: state.subscriptions.error
    }),
    shallowEqual
  );

  const fetchInfo = _ => {
    dispatch(afActions.fetchCompanyUsers());
  }

  // useEffect(() => {
  //   console.log(companyUsers);
  // }, [companyUsers]);

  useEffect(() => {
    fetchInfo();
  }, []);

  let goToUserSubscriptions = (userId) => {
    history.push(`/agentPanel/subscriptions/${userId}`);
  }

  const [columns, setColumns] = useState([
    {
      dataField: 'name',
      keyField: 'name',
      text: msg["USER.SETTINGS.NAME"],
      sort: true,
      sortCaret: sortCaret,
      formatter: (cellContent, row) => (
        <>
          {row.name}
        </>
      )
    },
    {
      dataField: 'surname',
      keyField: 'surname',
      text: msg["USER.SETTINGS.SURNAME"],
      sort: true,
      sortCaret: sortCaret,
      formatter: (cellContent, row) => (
        <>
          {row.surname}
        </>
      )
    },
    {
      dataField: 'email',
      keyField: 'email',
      text: msg["USER.SETTINGS.EMAIL"],
      sort: true,
      sortCaret: sortCaret,
      formatter: (cellContent, row) => (
        <>
          {row.email}
        </>
      )
    },
    {
      dataField: 'mobile',
      keyField: 'mobile',
      text: msg["USER.SETTINGS.PHONE"],
      sort: true,
      sortCaret: sortCaret,
      formatter: (cellContent, row) => (
        <>
          {row.mobile}
        </>
      )
    },
    {
      dataField: 'registrationDate',
      keyField: 'registrationDate',
      text: msg["USER.REGDATE"],
      sort: true,
      sortCaret: sortCaret,
      formatter: (cellContent, row) => (
        <>
          {row.registrationDate.replace('Z', '').replace('T', ' ').substr(0,19)}
        </>
      )
    },
    {
      dataField: 'id',
      keyField: 'id',
      text: msg["SUBSCRIPTIONS_LIST"],
      sort: true,
      sortCaret: sortCaret,
      formatter: (cellContent, row) => (
        <>
          {<Button key={`stationData${row.stationId}`} className="btn-success" onClick={() => goToUserSubscriptions(row.id)}>{msg["SUBSCRIPTIONS_LIST"]}</Button>}
        </>
      )
    }
  ]);

  return (
    <Card>
      <CardHeader title={title}>
        <CardHeaderToolbar>
          <button
            type="button"
            onClick={()=>history.push('/affiliatePanel')}
            className="btn btn-primary"
          >
            {msg["AGENT_INFO"]}
          </button>
          {user?.agentAccount && user?.agentAccount === true && user?.agentCompany == 'PZU' &&
          <button
            type="button"
            onClick={()=>history.push('/agentPanel/minTemp')}
            className="btn btn-success ml-2"
          >
            {msg["AGENT_MIN_TEMP"]}
          </button>}
          <button
            type="button"
            onClick={()=>{history.push('/agentPanel/newClient')}}
            className="btn btn-danger ml-2"
          >
            {msg["AGENT_CREATE_USER_ACCOUNT"]}
          </button>
        </CardHeaderToolbar>
      </CardHeader>
      <CardBody>
          {msg["AGENT_USERS_LIST"]} (<strong>{companyUsers.length}</strong>).

          {/* <Button className="btn-primary btn-sm ml-4" onClick={() => {fetchInfo()}}>
            {'OK'}
          </Button> */}
        <div className="mt-5">
        {companyUsers && <>
        <BootstrapTable keyField='id' data={companyUsers || []} columns={columns}
          wrapperClasses="table-responsive"
          bordered={false}
          classes="table table-head-custom table-vertical-center overflow-hidden"
          bootstrap4
          sort={{dataField: 'distance', order: 'asc'}}
        />
        </>}
        </div>
      </CardBody>
    </Card>
  );
}
