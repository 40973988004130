import React from 'react'
import { Button } from 'react-bootstrap';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import GetApp from '@material-ui/icons/GetApp';
import timezoneHelper from '../Helpers/timezoneHelper';
const { Interval, DateTime} = require("luxon");

function* days(interval) {
    let cursor = interval.start.startOf("day");
    while (cursor <= interval.end) {
      yield cursor;
      cursor = cursor.plus({ days: 1 });
    }
  }

export default function ExportMinTempsAsXLS({stationsData, dateFrom, dateTo}) {

    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const fileExtension = '.xlsx';

    const exportToXLS = async _ => {

        const fileName = `Minimalne temperatury`;
        let wb = {};
        let ws;
        let SheetNames = [];
        let Sheets = {};
        let i = 0;

        let rows = [];
        let row = [];
        i++;

        var df = dateFrom.split('-');
        var dt = dateTo.split('-');
        console.log(df);
        console.log(dt);

        let start = DateTime.local(df[0]-0, df[1]-0, df[2]-0);
        let end = DateTime.local(dt[0]-0,  dt[1]-0, dt[2]-0);
        let interval = Interval.fromDateTimes(start, end);

        let SheetName = `Minimalne temperatury`;

        SheetNames.push(SheetName);

        row=['Id stacji', 'Nazwa stacji', 'Latitude', 'Longitude'];

        for(var d of days(interval)) {
            row.push(d.toISO().substr(0,10));
        }

        rows.push(row);
        

        for (const station of stationsData.stationsCoords) {
            row = [station.stationId, station.stationName, station.lat, station.lng];
            let temps = stationsData.minTemps.filter(mt => mt.stationId == station.stationId);
            for(var d of days(interval)) {
                console.log(d);
                let day = d.toISO().substr(0,10);
                
                let foundMin = temps.find(entity => entity.date == day);

                if (foundMin) {
                    row.push(foundMin.value);
                } else {
                    row.push('-');
                }

            }


            rows.push(row);
        }


        

        
       
        var wscols = [
            { width: 30 },
            { width: 30 },
            { width: 30 }
        ];

        for (var w = 0; w < 62; w++) wscols.push({width:15});


        ws = XLSX.utils.aoa_to_sheet(rows);
        ws['!cols'] = wscols;

        Sheets[SheetName] = ws;

        wb = { Sheets, SheetNames };

        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array', Props: { Author: "FarmSmart.pl" } });
        const data = new Blob([excelBuffer], { type: fileType });
        FileSaver.saveAs(data, `${fileName}${fileExtension}`);
    }

    return (
        <Button variant="outlined" className="btn-sm btn-secondary mt-5" onClick={(e) => exportToXLS()}><GetApp /> xls</Button>
    )
}