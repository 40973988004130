/**
 * Entry application component used to compose providers and render Routes.
 * */

import React from "react";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { PersistGate } from "redux-persist/integration/react";
import { Routes } from "../app/Routes";
import { I18nProvider } from "../_metronic/i18n";
import ErrorBoundary from "./ErrorBoundary";
import { LayoutSplashScreen, MaterialThemeProvider } from "../_metronic/layout";
import setupAxios from "./modules/Shared/setupAxios";
import axios from "axios";
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from "react-toastify";
import TagManager from 'react-gtm-module'

const tagManagerArgs = {
  gtmId: 'GTM-KR9D9W8G'
}
TagManager.initialize(tagManagerArgs)

export default function App({ store, persistor, basename }) {
  setupAxios(axios, store);

  return (
    /* Provide Redux store */
    <ErrorBoundary>
      <Provider store={store}>
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnVisibilityChange
          draggable
          pauseOnHover
        />
        {/* Asynchronously persist redux stores and show `SplashScreen` while it's loading. */}
        <PersistGate persistor={persistor} loading={<LayoutSplashScreen />}>
          {/* Add high level `Suspense` in case if was not handled inside the React tree. */}
          <React.Suspense fallback={<LayoutSplashScreen />}>
            {/* Override `basename` (e.g: `homepage` in `package.json`) */}
            <BrowserRouter basename={basename}>
              {/*This library only returns the location that has been active before the recent location change in the current window lifetime.*/}
              <MaterialThemeProvider>
                {/* Provide `react-intl` context synchronized with Redux state.  */}
                <I18nProvider>
                  {/* Render routes with provided `Layout`. */}
                  <Routes store={store} />
                </I18nProvider>
              </MaterialThemeProvider>
            </BrowserRouter>
          </React.Suspense>
        </PersistGate>
      </Provider>
    </ErrorBoundary>
  );
}

